var	$header_el = $('.content-block-homepage-header:first'),
	$window	= $(window),

	raf	= window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            // old IE Fallback
            function(callback){
            	window.setTimeout(callback, 1000/60);
            },
	update = function() {
		var scroll_y = document.body.scrollTop,
			wh = $window.height();

		$header_el.css('background-position', 'center -' + (scroll_y >> 1) + 'px');

		raf(update);
	};


$(function() {
	console.log('main.js in DEV');

    FastClick.attach(document.body);


    $('.scroll-to-element').on('click', function(event) {
    	event.preventDefault();

    	$('html, body').animate({
    		scrollTop: $($(this).data('element')).first().offset().top
    	}, 400);
    });


    // header
    // $('.content-block-homepage-sub-header').waypoint({
    // 	handler: function(direction) {
    // 		console.log(this, direction, 'waypoint');
    // 		$('.content-block-homepage-header').toggleClass('animate-in', direction == 'down');
    // 	},
    // 	offset: '50%'
    // });

    // animate arrangementen in wanneer ze in beeld komen
    $('.content-arrangement:first').waypoint({
    	handler: function(direction) {

    		$('.content-arrangement').addClass('animate-in');
    	},
    	offset: '95%'
    });

    var audio_el = $('#audio').get(0);
    audio_el.onplaying = function() {
        is_playing = true;
        $('.toggle-audio').removeClass('muted');
    };
    audio_el.onpause = function() {
        is_playing = false;
        $('.toggle-audio').addClass('muted');
    };

    // initial state
    $('.toggle-audio').toggleClass('muted', audio_el.paused);

    $('.toggle-audio').on('click', function(event) {
        event.preventDefault();

        if(audio_el.paused) {
            audio_el.play();
        } else {
            audio_el.pause();
        }
    });
    // update();
});
